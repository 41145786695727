import api from '@/utils/api';
import registerServer from '@/utils/registerServer';
import request from '@/utils/request';

const {
  login,
  userlogin,
  logout,
  register,
  userRegister,
  createUser,
  updateUser,
  deleteUser,
  changeUserStatus,
  userList,
  resetPassword,
  setting,
  consumerSetting,
  user_info,
  tenant_info,
  factories_list,
  llm_list,
  my_llm,
  set_api_key,
  set_tenant_info,
  add_llm,
  delete_llm,
  getSystemStatus,
  getSystemVersion,
} = api;

const methods = {
  login: {
    url: login,
    method: 'post',
  },
  userlogin: {
    url: userlogin,
    method: 'post',
  },
  logout: {
    url: logout,
    method: 'get',
  },
  register: {
    url: register,
    method: 'post',
  },
  userRegister: {
    url: userRegister,
    method: 'post',
  },
  setting: {
    url: setting,
    method: 'post',
  },
  user_info: {
    url: user_info,
    method: 'get',
  },
  get_tenant_info: {
    url: tenant_info,
    method: 'get',
  },
  set_tenant_info: {
    url: set_tenant_info,
    method: 'post',
  },
  factories_list: {
    url: factories_list,
    method: 'get',
  },
  llm_list: {
    url: llm_list,
    method: 'get',
  },
  my_llm: {
    url: my_llm,
    method: 'get',
  },
  set_api_key: {
    url: set_api_key,
    method: 'post',
  },
  add_llm: {
    url: add_llm,
    method: 'post',
  },
  delete_llm: {
    url: delete_llm,
    method: 'post',
  },
  getSystemStatus: {
    url: getSystemStatus,
    method: 'get',
  },
  getSystemVersion: {
    url: getSystemVersion,
    method: 'get',
  },
  getUserList: {
    url: userList,
    method: 'get',
  },
  createUser: {
    url: createUser,
    method: 'post',
  },
  updateUser: {
    url: updateUser,
    method: 'post',
  },
  deleteUser: {
    url: deleteUser,
    method: 'post',
  },
  changeUserStatus: {
    url: changeUserStatus,
    method: 'post',
  },
  resetPassword: {
    url: resetPassword,
    method: 'post',
  },
  consumerSetting: {
    url: consumerSetting,
    method: 'post',
  }
} as const;

const userService = registerServer<keyof typeof methods>(methods, request);

export default userService;
