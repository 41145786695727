import { BaseState } from '@/interfaces/common';
import { IUser } from '@/interfaces/database/user-manager';
import i18n from '@/locales/config';
import userService from '@/services/userService';
import { message } from 'antd';
import { DvaModel } from 'umi';

export interface userManagerModelState extends BaseState {
  info: any;
  data: IUser[];
  total: number;
  currentRecord: Nullable<IUser>;
}

const model: DvaModel<userManagerModelState> = {
  namespace: 'userManagerModel',
  state: {
    data: [],
    info: {},
    total: 0,
    currentRecord: null,
    pagination: {
      current: 1,
      pageSize: 10,
    },
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    setCurrentRecord(state, { payload }) {
      return { ...state, currentRecord: payload };
    },
    setPagination(state, { payload }) {
      return { ...state, pagination: { ...state.pagination, ...payload } };
    },
  },
  effects: {

    *createUser({ payload = {} }, { call, put }) {
      const { data } = yield call(userService.createUser, payload);
      const { retcode } = data;
      if (retcode === 0) {
        message.success(i18n.t('message.created'));
        yield put({
          type: 'getUserList'
        });
      }
      return retcode;
    },
    *updateUser({ payload = {} }, { call }) {
      const { data } = yield call(userService.updateUser, payload);
      const { retcode } = data;
      if (retcode === 0) {
        message.success(i18n.t('message.modified'));
      }
    },
    *removeUser({ payload = {} }, { call, put }) {
      const { data } = yield call(userService.deleteUser, {
        id: payload.id,
      });
      const { retcode } = data;
      if (retcode === 0) {
        message.success(i18n.t('message.deleted'));
        yield put({
          type: 'getUserList'
        });
      }
      return retcode;
    },
    *resetPassword({ payload = {} }, { call, put }) {
      const { data } = yield call(userService.resetPassword, {
        id: payload.id,
      });
      const { retcode, data: password } = data;
      if (retcode === 0) {
        message.success(i18n.t('message.operated'));
        yield put({
          type: 'getUserList'
        });
      }
      return {
        retcode,
        password
      };
    },
    *changeUserStatus({ payload = {} }, { call, put }) {
      const { data } = yield call(
        userService.changeUserStatus,
        {
          id: payload.id,
          status: payload.status
        },
      );
      const { retcode } = data;
      if (retcode === 0) {
        message.success(i18n.t('message.modified'));
        yield put({
          type: 'getUserList',
        });
      }
    },

    *getUserList({ payload = {} }, { call, put, select }) {
      const state: userManagerModelState = yield select((state: any) => state.userManagerModel);
      const requestBody = {
        ...payload,
        page: state.pagination.current,
        page_size: state.pagination.pageSize,
      };
      // if (state.searchString) {
      //   requestBody['keywords'] = state.searchString;
      // }
      const { data } = yield call(userService.getUserList, requestBody);
      const { retcode, data: res } = data;

      if (retcode === 0) {
        yield put({
          type: 'updateState',
          payload: {
            data: res.consumers,
            total: res.total,
          },
        });
      }
    },
  },
};
export default model;
